<template>
  <div class="wrapper">
    <h4>{{ titleName }}</h4>
    <el-form :model="form" ref="form" :rules="rules" label-width="100px">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" placeholder="请输入姓名" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="性别" prop="sex">
            <el-select v-model="form.sex" placeholder="请选择性别" style="width:100%">
              <el-option v-for="item in sexList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="年龄" prop="age">
            <el-input v-model="form.age" placeholder="请输入年龄" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="手机号" prop="contract">
            <el-input v-model="form.contract" placeholder="请输入手机号" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="身份证号" prop="idNum">
            <el-input v-model="form.idNum" placeholder="请输入身份证号" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="报告号" prop="reportNum">
            <el-input v-model="form.reportNum" placeholder="请输入报告号" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="体检点位" prop="checkPlace">
            <el-input v-model="form.checkPlace" placeholder="请选择体检点位" auto-complete="off" :disabled="true">
              <el-button type="primary" slot="append" icon="el-icon-search" @click="selectCheckPlace()"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="体检日期" prop="checkTime">
            <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" v-model="form.checkTime" type="datetime" placeholder="选择体检日期"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="身高" prop="height">
            <el-input v-model="form.height" placeholder="请输入身高" auto-complete="off">
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="体重" prop="weight">
            <el-input v-model="form.weight" placeholder="请输入身高" auto-complete="off">
              <template slot="append">kg</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="体质指数" prop="bodyMass">
            <el-input v-model="form.bodyMass" placeholder="请输入体质指数" auto-complete="off">
              <template slot="append">-</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="脂肪率" prop="fatRate">
            <el-input v-model="form.fatRate" placeholder="请输入脂肪率" auto-complete="off">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="腰围" prop="waistline">
            <el-input v-model="form.waistline" placeholder="请输入腰围" auto-complete="off">
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="臀围" prop="hipline">
            <el-input v-model="form.hipline" placeholder="请输入臀围" auto-complete="off">
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="腰臀比" prop="waistHipRate">
            <el-input v-model="form.waistHipRate" placeholder="请输入腰臀比" auto-complete="off">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="收缩压" prop="systolicPressure">
            <el-input v-model="form.systolicPressure" placeholder="请输入收缩压" auto-complete="off">
              <template slot="append">mmHg</template>
            </el-input>
          </el-form-item>
        </el-col>
         <el-col :span="8">
          <el-form-item label="舒张压" prop="diastolicPressure">
            <el-input v-model="form.diastolicPressure" placeholder="请输入舒张压" auto-complete="off">
              <template slot="append">mmHg</template>
            </el-input>
          </el-form-item>
        </el-col> 
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="脉搏" prop="pulse">
            <el-input v-model="form.pulse" placeholder="请输入脉搏" auto-complete="off">
              <template slot="append">次/分</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="血糖" prop="glycemicIndex">
            <el-input v-model="form.glycemicIndex" placeholder="请输入血糖" auto-complete="off">
              <template slot="append">mmol/L</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="尿酸（嘌呤）" prop="uricAcid">
            <el-input v-model="form.uricAcid" placeholder="请输入尿酸（嘌呤）" auto-complete="off">
              <template slot="append">μmol/L</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="总胆固醇" prop="totalCholesterol">
            <el-input v-model="form.totalCholesterol" placeholder="请输入总胆固醇" auto-complete="off">
              <template slot="append">mmol/L</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="体温" prop="temperature">
            <el-input v-model="form.temperature" placeholder="请输入体温" auto-complete="off">
              <template slot="append">℃</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="血氧" prop="bloodOxygen">
            <el-input v-model="form.bloodOxygen" placeholder="请输入血氧" auto-complete="off">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="心电" prop="ecg">
            <el-input v-model="form.ecg" placeholder="请输入心电" auto-complete="off">
              <template slot="append">次/分</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="选择体检点位" :visible.sync="visible" width="50%" :close-on-click-modal="false">
      <div>
        <div>
          体检点位名称：
          <el-input style="width:200px;margin-right:10px;" size="medium" v-model="searchData.centerName" placeholder="请输入名称"></el-input>
          <el-button size="medium" type="primary" @click="search">搜索</el-button>
        </div>
        <div style="margin-top:10px;">
          <el-table :data="tableData" ref="singleTable" highlight-current-row border size="medium" :height="height">
            <el-table-column label="" width="40">
              <template slot-scope="scope">
                <el-radio
                  :label="scope.$index"
                  v-model="radio"
                  @change.native="getCurrentRow(scope.row)"
                  style="color: #fff;padding-left: 10px; margin-right: -25px;"
                ></el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="centName" label="名称"></el-table-column>
            <el-table-column prop="centStatusName" label="状态" width="120"></el-table-column>
            <el-table-column prop="natureName" label="性质" width="120"></el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 10px;">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="tablePage.currentPage"
              :page-sizes="[5, 10, 15]"
              :page-size="tablePage.pageSize"
              layout="total,jumper,prev, pager, next,sizes"
              :total="tablePage.totalResult"
            ></el-pagination>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="cancel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'AddOrEdit',
  data() {
    return {
      radio: '',
      height: 240,
      tableData: [],
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true,
      },
      visible: false,
      searchData: {
        centerName: '',
      },
      titleName: '',
      sexList: [
        {
          value: '男',
          label: '男',
        },
        {
          value: '女',
          label: '女',
        },
      ],
      form: {
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        age: [
          { required: true, message: '请输入年龄', trigger: 'blur' },
          { pattern: /^[1-9]\d*$/, message: '请检查年龄格式' },
        ],
        contract: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^(1[3456789]\d{9})$/, message: '请检查手机号格式' },
        ],
        idNum: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请检查身份证号格式' },
        ],
        reportNum: [
          { required: true, message: '请输入报告号', trigger: 'blur' },
          { pattern: /^\d{1,}$/, message: '请检查报告号格式' },
        ],
        checkTime: [{ required: true, message: '请选择体检日期', trigger: 'blur' }],
        height: [{ pattern: /^(0{1}|[1-9]\d{0,3}|.{0})$/, message: '请检查身高格式' }],
        weight: [{ pattern: /^(0(\.\d{1}){0,1}|[1-8]\d{1,3}(\.\d{1}){0,1}|9\d{1,2}(\.\d{1}){0,1}|999(\.0){0,1}|.{0})$/, message: '请检查体重格式' }],
        bodyMass: [{ pattern: /^([1-9]\d*\.?\d*)$/, message: '请检查体质指数格式' }],
        fatRate: [{ pattern: /^(([1-9]?\d(\.\d{1,2})?)|100|100.00)$/, message: '请检查脂肪率格式' }],
        waistline: [{ pattern: /^[1-9]\d*$/, message: '请检查腰围格式' }],
        hipline: [{ pattern: /^[1-9]\d*$/, message: '请检查臀围格式' }],
        waistHipRate: [{ pattern: /^(([1-9]?\d(\.\d{1,2})?)|100|100.00)$/, message: '请检查腰臀比格式' }],
        systolicPressure: [{ pattern: /^[1-9]\d*$/, message: '请检查收缩压格式' }],
        diastolicPressure: [{ pattern: /^[1-9]\d*$/, message: '请检查舒张压格式' }],
        pulse: [{ pattern: /^[1-9]\d*$/, message: '请检查脉搏格式' }],
        glycemicIndex: [{ pattern: /^([1-9]\d*\.?\d*)$/, message: '请检查血糖格式' }],
        uricAcid: [{ pattern: /^[1-9]\d*$/, message: '请检查尿酸（嘌呤）格式' }],
        totalCholesterol: [{ pattern: /^([1-9]\d*\.?\d*)$/, message: '请检查总胆固醇格式' }],
        temperature: [{ pattern: /^([1-9]\d*\.?\d*)$/, message: '请检查体温格式' }],
        bloodOxygen: [{ pattern: /^(([1-9]?\d(\.\d{1,2})?)|100|100.00)$/, message: '请检查血氧格式' }],
        ecg: [{ pattern: /^[1-9]\d*$/, message: '请检查心电格式' }],
      },
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['addPubHealthReport', 'updatePubHealthReport', 'getSumCenterList']),
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    getLeaveTime(data) {
      this.form.leaveTime = data
    },
    getFactTime(data) {
      this.form.factTime = data
    },
    initView() {
      if (this.routeType == 'add') {
        this.titleName = '添加健康管理报告'
      } else {
        this.titleName = '修改健康管理报告'
        this.itemData = JSON.parse(this.$route.query.item)
        this.form = {
          id: this.itemData.id,
          name: this.itemData.name,
          sex: this.itemData.sex,
          age: this.itemData.age,
          contract: this.itemData.contract,
          idNum: this.itemData.idNum,
          reportNum: this.itemData.reportNum,
          checkPlace: this.itemData.checkPlace,
          checkTime: this.itemData.checkTime,
          height: this.itemData.height,
          weight: this.itemData.weight,
          bodyMass: this.itemData.bodyMass,
          fatRate: this.itemData.fatRate,
          waistline: this.itemData.waistline,
          hipline: this.itemData.hipline,
          waistHipRate: this.itemData.waistHipRate,
          systolicPressure: this.itemData.systolicPressure,
          diastolicPressure: this.itemData.diastolicPressure,
          pulse: this.itemData.pulse,
          glycemicIndex: this.itemData.glycemicIndex,
          uricAcid: this.itemData.uricAcid,
          totalCholesterol: this.itemData.totalCholesterol,
          temperature: this.itemData.temperature,
          bloodOxygen: this.itemData.bloodOxygen,
          ecg: this.itemData.ecg,
        }
      }
      this.getCheckPlace()
    },
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            ...this.form,
            createUserId: null,
            lastUpdUserId: null,
            tenantId: null,
          }
          params = this.form
          let server
          if (this.routeType == 'add') {
            server = 'addPubHealthReport'
          } else {
            server = 'updatePubHealthReport'
          }
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: 'success',
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    getCurrentRow(row) {
      this.form.checkPlace = row.centName
    },
    search() {
      this.getCheckPlace()
    },
    handleSizeChange: function(size) {
      this.tablePage.pageSize = size
      this.getCheckPlace()
    },
    // 控制页面的切换
    handleCurrentChange: function(currentPage) {
      this.tablePage.currentPage = currentPage
      this.getCheckPlace()
    },
    getCheckPlace() {
      let params = {
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          centerName: this.searchData.centerName,
        },
      }
      let server = 'getSumCenterList'
      this[server](params).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
      })
    },
    selectCheckPlace() {
      this.visible = true
    },
    cancel() {
      this.visible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow-y: auto;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
</style>
